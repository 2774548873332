/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable complexity */
import { Field } from "formik";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Direction, getTrackBackground, Range } from "react-range";
import { Box, Grid } from "theme-ui";
import log from "../../lib/log";

const SizeSlider: React.FunctionComponent<
	Readonly<
		Pick<
			React.InputHTMLAttributes<HTMLInputElement>,
			"onChange" | "onBlur" | "name" | "id"
		> &
			Readonly<{
				readonly setFieldValue: (
					field: string,
					value: unknown,
					shouldValidate?: boolean | undefined
				) => void;
				readonly value: Readonly<string | number>;
				readonly min: number;
				readonly max: number;
				readonly objectId: string;
				readonly error: string | undefined;
			}>
	>
	// eslint-disable-next-line max-lines-per-function
> = ({ max, min, name, id, value, onChange, onBlur, setFieldValue, error }) => {
	return (
		<Grid sx={{ gap: 1, gridTemplateColumns: "4fr minmax(3em, 80px)" }}>
			<ErrorBoundary
				FallbackComponent={({ error }) => (
					<Box>Range component has failed to load. {error.message}</Box>
				)}
			>
				<Range
					min={min}
					max={max}
					step={0.001}
					values={[value as number]}
					onChange={(values: readonly number[]) => {
						// unify onChange for both events (Event | values[])
						if (values && name) {
							setFieldValue(name, values[0], true);
						}
					}}
					allowOverlap={false}
					rtl={false}
					disabled={false}
					draggableTrack={false}
					direction={Direction.Right}
					renderTrack={({ props, children }) => (
						<Grid
							{...props}
							style={{
								...props.style,
								background: getTrackBackground({
									values: [value as number],
									colors: [
										"var(--theme-ui-colors-accent)",
										"var(--accent-bg-color)",
									],
									min: min,
									max: max,
								}),
							}}
							sx={{
								borderRadius: [2],
								height: 36,
								margin: "0 24px",
								background: "background",
								alignItems: "center",
								alignSelf: "center",
								"&:focus,&:hover": {
									backgroundColor: "accent-bg-90",
								},
							}}
						>
							{children}
						</Grid>
					)}
					renderThumb={({ props }) => (
						<Grid
							{...props}
							style={{ ...props.style, height: 36, width: 36 }}
							sx={{
								backgroundColor: "accent-bg",
								boxShadow: "button",
								border: "button",
								borderRadius: [1],
							}}
							title="Change size"
						></Grid>
					)}
				/>
			</ErrorBoundary>
			{/* <ErrorMessage name={name ?? "size"} /> */}
			<Field
				id={id}
				// variant="mono"
				sx={{
					// fontSize: [2, 3],
					borderRadius: "rounded",
					// padding: "0 8px",
					p: 0,
					textAlign: "center",
					borderColor: error ? "alert" : "glow",
					outlineColor: error ? "alert" : "glow",
					"&:hover,&:focus": {
						borderColor: error ? "alert" : "glow",
						boxShadow: error ? "alert" : "glow",
					},
				}}
				// inputMode="decimal"
				type="text"
				name={name ?? "size"}
				maxLength={6}
				value={value}
				onBlur={(e: Readonly<React.FocusEvent<Readonly<HTMLInputElement>>>) => {
					onBlur && onBlur(e);
				}}
				onChange={onChange}
				onFocus={(
					e: Readonly<React.FocusEvent<Readonly<HTMLInputElement>>>
				): void => {
					if (!e.target) {
						log.info("Invalid value", value, min, max);
						return;
					}

					e.target.select();
				}}
				// validate={(value: number) => {
				// 	if (typeof value != "number" && isNaN(value)) {
				// 		log.info('Invalid value', value, min, max)
				// 		return false;
				// 	}

				// 	if (value < min || value > max) {
				// 		log.info('Invalid size', value, min, max)
				// 		return false;
				// 	}

				// 	log.info('validate true')
				// 	return true;
				// }}
				// component={Input}
			/>
		</Grid>
	);
};

export default SizeSlider;
