import React from "react";
import { Grid, GridProps, Heading } from "theme-ui";

type Labels = {
  readonly top?: string;
  readonly mid?: string;
  readonly bottom?: string;
};

interface Properties extends GridProps {
  readonly labels: Labels;
  readonly size?: string;
}

// eslint-disable-next-line max-lines-per-function
const Labels: React.FC<Properties> = ({ labels, size, ...props }) => {
  const { top, mid, bottom } = labels;

  return (
    <Grid
      gap={0}
      sx={{
        pointerEvents: "all",
        cursor: "pointer",
        alignItems: "center",
        userSelect: "text",
      }}
      {...props}
    >
      {top && (
        <Heading sx={{ fontSize: [1, 2], alignSelf: "end" }} as="h4">
          {top}
        </Heading>
      )}
      {mid && (
        <Heading
          sx={{ fontSize: [2, 2, 3], marginY: "0" }}
          style={{ margin: 0 }}
        >
          {mid} {size ? `—  ${size}` : ""}
        </Heading>
      )}
      {bottom && (
        <Heading
          sx={{
            fontSize: [0, 0, 1, 2],
            fontWeight: "normal",
            opacity: 0.9,
          }}
          style={{ margin: 0 }}
          as="h4"
        >
          {bottom}
        </Heading>
      )}
    </Grid>
  );
};

export default Labels;
