/* eslint-disable max-lines-per-function */
import styled from "@emotion/styled";
import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Box, Grid, Label, Radio } from "theme-ui";
import { EcogardenFabricTextbox } from "../../lib/fabric/objects";
import { sizeToNumber } from "../../lib/text";

const ErrorBlock = styled(Box)`
  font-size: 0.8em;
  background-color: var(--theme-ui-colors-alert);
  padding: 0 4px;

  &:before {
    content: "⚠ ";
  }
`;

type Sizes = "s" | "m" | "l";

// readonly size: "small" | "medium" | "large";
// readonly setSize: React.Dispatch<React.SetStateAction<Sizes>>;
// readonly onSelected?: () => void;
const TextSize: React.FunctionComponent<{
  readonly textSize?: Sizes;
  readonly object?: EcogardenFabricTextbox;
  readonly onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ textSize, object, onBlur, onChange }) => {
  const [size, setSize] = useState(textSize);

  console.log("size", size, textSize);

  useEffect(() => {
    if (object) {
      object.animate("fontSize", sizeToNumber(size), {
        duration: 140,
        onChange: () => {
          object.canvas?.requestRenderAll();
        },
      });
      object.canvas?.requestRenderAll();
    }
  });
  return (
    <Box
      sx={{
        p: 2,
        border: (theme) => `0.4em solid {theme.colors['accent-bg']}`,
      }}
    >
      <Box sx={{ opacity: 0.8, py: [1, 2] }}>Text Size</Box>

      <Grid
        sx={{
          gridTemplateColumns: "1fr 1fr 1fr",
          justifyContent: "center",
        }}
      >
        <Label
          sx={{
            alignItems: "center",
            padding: 2,
            backgroundColor: size === "s" ? "accent-bg-90" : "transparent",
            "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
            justifyContent: "center",
          }}
        >
          <Radio
            type="radio"
            name="textSize"
            checked={size === "s"}
            onChange={(e) => {
              setSize("s");
              (e.target as HTMLInputElement).blur();
              onChange(e);
            }}
            value="s"
          />
          Small
        </Label>
        <Label
          sx={{
            alignItems: "center",
            padding: 2,
            backgroundColor: size === "m" ? "accent-bg-90" : "transparent",
            "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
            justifyContent: "center",
          }}
        >
          <Radio
            type="radio"
            name="textSize"
            checked={size === "m" || size === undefined}
            onChange={(e) => {
              setSize("m");
              (e.target as HTMLInputElement).blur();
              onChange(e);
            }}
            value="m"
          />
          Medium
        </Label>
        <Label
          sx={{
            alignItems: "center",
            padding: 2,
            backgroundColor: size === "l" ? "accent-bg-90" : "transparent",
            "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
            justifyContent: "center",
          }}
        >
          <Radio
            type="radio"
            name="textSize"
            checked={size === "l"}
            onChange={(e) => {
              setSize("l");
              (e.target as HTMLInputElement).blur();
              onChange(e);
            }}
            value="l"
          />
          Large
        </Label>
      </Grid>
      <ErrorMessage name="textSize" component={ErrorBlock} />
    </Box>
  );
};

export default TextSize;
