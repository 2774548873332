import React from "react";
import { StateWithHistory } from "redux-undo";
import { GridProps } from "theme-ui";
import { EcogardenObject, EcogardenPolygon } from "../../lib/objects";
import GroupSelection from "./GroupSelection";
import PlantSelection from "./PlantSelection";

interface SelectionProperty {
  readonly selection:
    | StateWithHistory<readonly (EcogardenObject | EcogardenPolygon)[]>
    | readonly (EcogardenObject | EcogardenPolygon)[];
}

/**
 * Selection Panel - Title
 */
const Title: React.FunctionComponent<SelectionProperty & GridProps> = ({
  selection,
  ...properties
}) => {
  if ("present" in selection) {
    selection = selection.present as readonly (
      | EcogardenObject
      | EcogardenPolygon
    )[];
  }

  if (selection.length === 0) {
    return <></>;
  }

  if (selection.length === 1) {
    return <PlantSelection selection={selection[0]} {...properties} />;
  }

  if (selection.length > 1) {
    return <GroupSelection selection={selection} {...properties} />;
  }

  return <></>;
};

export default Title;
