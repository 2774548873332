import { List } from "@styled-icons/feather/List";
import { useState } from "react";
import IconButton from "../buttons/IconButton";
import ObjectsList from "./ObjectsList";

const ObjectsContainer = () => {
  const [isOpen, setIsOpen] = useState(false);

  if (!isOpen) {
    return (
      <IconButton
        icon={List}
        label="List"
        sx={{ marginX: 1 }}
        onClick={() => {
          setIsOpen((v) => (v ? false : true));
        }}
      />
    );
  }

  return (
    <ObjectsList
      onCloseRequest={() => {
        setIsOpen((v) => (v ? false : true));
      }}
    />
  );
};

export default ObjectsContainer;
