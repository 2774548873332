/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
/** @jsxImportSource theme-ui */
import styled from "@emotion/styled";
import { ErrorMessage, FieldMetaProps, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { Box, Label } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import { useAppSelector } from "../../lib/configureStore";
import { findAndSyncSizes, findObject } from "../../lib/fabric/objects";
import log from "../../lib/log";
import { findNumber } from "../../lib/utils";
import {
	composeScale,
	fitIntoSize,
	getMaxSize,
	getMinSize,
	Shape,
} from "../../shapes";
import { FormValues } from "./Edit";
import SizeSlider from "./SizeSlider";
import * as ecogardenObjects from "../../lib/objects";

const ErrorBlock = styled(Box)`
	font-size: 0.8em;
	background-color: var(--theme-ui-colors-alert);
	padding: 0 4px;

	&:before {
		content: "⚠ ";
	}
`;

const onScaleChange =
	(canvas: Readonly<fabric.Canvas | undefined>) =>
	// eslint-disable-next-line max-lines-per-function
	(objectId: string) =>
	(name: "width" | "depth" | "size") =>
	(size: number) => {
		if (!canvas) {
			return;
		}

		const object = findObject(canvas.getObjects())(objectId);

		// If we have a supported name
		if (!object) {
			return;
		}

		const minFeet = getMinSize(object.subtype as Shape | undefined)(name);
		const maxFeet = getMaxSize(object.subtype as Shape | undefined)(name);

		log.debug("min feet", name, minFeet);
		log.debug("max feet", name, maxFeet);
		log.debug("size", name, size);

		const validatedSize = fitIntoSize(minFeet)(maxFeet)(size);

		const validatedSizeInPixels = ecogardenObjects.feetToPixels(validatedSize);
		if (!validatedSizeInPixels) {
			return;
		}

		log.debug(
			"validated size",
			name,
			validatedSize,
			validatedSizeInPixels,
			"px"
		);

		const scale = ecogardenObjects.sizeToScale(
			object.subtype as Shape | undefined
		)(name)(validatedSizeInPixels);

		log.debug("size to scale", name, scale);

		if (!scale) {
			return;
		}

		const scales = composeScale(name)(scale);

		log.debug("scales", scales);

		if (scales.scaleX) {
			object.set("scaleX", scales.scaleX);
		}

		if (scales.scaleY) {
			object.set("scaleY", scales.scaleY);
		}

		if (scales.scaleX || scales.scaleY) {
			object.setCoords();
		}

		canvas.fire("object:scaling", { target: object });
		canvas.requestRenderAll();
	};

type Props = {
	readonly max: number;
	readonly min: number;
	readonly value: number | string | undefined;
	readonly name: string;
	readonly objectId: string;
	readonly label?: string;
	readonly onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * Component to edit the size of the object
 */
// eslint-disable-next-line max-lines-per-function
const EditSize: React.FunctionComponent<
	Readonly<
		Props &
			FieldMetaProps<string | number> &
			Pick<FormikHelpers<FormValues>, "setFieldValue">
		// eslint-disable-next-line max-lines-per-function
	>
> = ({
	max,
	min,
	objectId,
	name,
	error,
	label,
	value,
	setFieldValue,
	onChange,
	onBlur,
}) => {
	const { canvas, objects } = useAppSelector((state) => ({
		canvas: getCanvas(state.canvas),
		objects: state.objects,
	}));

	useEffect(() => {
		if (!canvas) {
			return;
		}

		const onSelectionCleared = (): void => {
			findAndSyncSizes(canvas.getObjects())(objects.present)(objectId);
		};

		// reset size before selection is cleared
		canvas.on("before:selection:cleared", onSelectionCleared);

		return function cleanup() {
			canvas.off("before:selection:cleared", onSelectionCleared);
		};
	});

	// eslint-disable-next-line complexity
	useEffect(() => {
		if (error) {
			return;
		}

		const size = findNumber(value);
		if (
			(name === "width" || name === "depth" || name === "size") &&
			size !== undefined
		) {
			onScaleChange(canvas)(objectId)(name)(size);
		}
	}, [error, canvas, objectId, name, value]);

	return (
		<Box px={2}>
			<Label htmlFor={`edit-size-${name}`}>{label ?? "Size (in feet)"}</Label>
			<SizeSlider
				max={max}
				min={min}
				name={name}
				id={`edit-size-${name}`}
				value={value}
				setFieldValue={setFieldValue}
				objectId={objectId}
				onChange={onChange}
				onBlur={(e) => {
					onBlur && onBlur(e);
				}}
				error={error}
			/>
			<ErrorMessage name={name} component={ErrorBlock} />
		</Box>
	);
};

export default EditSize;
