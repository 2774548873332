import React from "react";
import { Grid } from "theme-ui";
import Deselect from "./Deselect";

/** Deselect selection control */
/** Selection Controls Block */
const Controls: React.FunctionComponent<{
  readonly onCancelRequest: React.MouseEventHandler;
}> = ({ onCancelRequest }) => <Deselect onClick={onCancelRequest} />;

export default Controls;
