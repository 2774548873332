/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { withFormik } from "formik";
import React from "react";
import { connect, useStore } from "react-redux";
import type { Dispatch, Store } from "redux";
import { Box } from "theme-ui";
import * as yup from "yup";
import { modifiedObject } from "../../actions/objects";
import type { RootState } from "../../lib/configureStore";
import log from "../../lib/log";
import {
	EcogardenLine,
	EcogardenObject,
	EcogardenObjects,
	EcogardenPath,
	EcogardenPolygon,
	EcogardenTextbox,
	feetToPixels,
	findObject,
	getScaledHeight,
	getScaledWidth,
	pixelsToFeet,
	sizeToScale,
} from "../../lib/objects";
import { PathSizes } from "../../lib/path";
import { TextSizes } from "../../lib/text";
import { findShapeDetail, getAvailableSizes, Shape } from "../../shapes";
import EditForm from "./EditForm";

/**
 * Calculate the scales on the object
 */
// eslint-disable-next-line complexity
const toScale = (
	values: FormValues
): {
	readonly scaleX?: number;
	readonly scaleY?: number;
} => {
	const availableSizes = getAvailableSizes(values.subtype);

	const sizeTo = sizeToScale(values.subtype);

	const scales: { scaleX?: number; scaleY?: number } = {};

	if (availableSizes.includes("size") && values.size !== undefined) {
		const scale = sizeTo("size")(feetToPixels(values.size));
		scales.scaleX = scale;
		scales.scaleY = scale;
		// if we have a width ? we should calculate the scale
		// FIXME: seems like most values would have a width so this sets the scale inappropriately
	} else if (
		values.width !== undefined &&
		values.type !== "textbox" &&
		values.type !== "line" &&
		values.type !== "path"
	) {
		scales.scaleX = sizeTo("width")(feetToPixels(values.width));
	}

	if (availableSizes.includes("depth") && values.depth !== undefined) {
		scales.scaleY = sizeTo("depth")(feetToPixels(values.depth));
	}

	return scales;
};

type Mutable<T> = {
	-readonly [P in keyof T]: T[P];
};

const submit =
	(store: Store<RootState>) =>
	(onFinish: () => void) =>
	// eslint-disable-next-line complexity
	(values: FormValues): void => {
		if (!values.subtype) {
			log.debug("Invalid subtype to submit, finishing without saving");
			onFinish();
			return;
		}

		const state = store.getState();
		const ecogardenObject = findObject(state.objects.present)(values.id);

		if (ecogardenObject) {
			const modified: Pick<
				EcogardenObjects,
				"id" | "label" | "scaleX" | "scaleY" | "fill" | "stroke"
			> = {
				// ...ecogardenObject,
				id: values.id,
				label: values.label,
				...toScale(values),
				fill: values.fill,
				stroke: values.stroke,
			};

			if (ecogardenObject.type === "textbox") {
				(modified as Mutable<EcogardenTextbox>).size = values.textSize ?? "m";
			}

			if (ecogardenObject.type === "line") {
				(modified as Mutable<EcogardenLine>).strokeWidth =
					values.strokeWidth ?? "medium";
			}

			if (ecogardenObject.type === "path") {
				(modified as Mutable<EcogardenPath>).strokeWidth =
					values.strokeWidth ?? "medium";
			}

			store.dispatch(modifiedObject(modified));
		}

		onFinish();
		return;
	};

/**
 * Validation schema generation for different sizes
 */
// eslint-disable-next-line complexity
export const sizesValidationSchema =
	(type?: string) =>
	// eslint-disable-next-line complexity
	(
		subtype: string | undefined
	): {
		readonly width?: yup.NumberSchema<number | undefined, object>;
		readonly depth?: yup.NumberSchema<number | undefined, object>;
		readonly size?: yup.NumberSchema<number | undefined, object>;
	} => {
		// Skipping textbox
		// TODO: We probably shouldn't be checking for textbox here,
		// just don't call this function unless we need the sizes
		if (type === "textbox") {
			log.debug("skipping sizes validation for textbox");
			return {};
		}

		if (type === "line") {
			log.debug("skipping sizes validation for line");
			return {};
		}

		if (type === "path") {
			log.debug("skipping sizes validation for path");
			return {};
		}

		const shapeDetail = findShapeDetail(subtype);

		if (!shapeDetail) {
			// Default
			return {};
		}

		if (shapeDetail.shape?.includes("rect") || shapeDetail?.shape === "rect") {
			const widthMin = shapeDetail.minWidth ?? 0;
			const widthMax = shapeDetail.maxWidth ?? 100;

			const depthMin = shapeDetail.minDepth ?? shapeDetail.minSize ?? 0;
			const depthMax = shapeDetail.maxDepth ?? shapeDetail.maxSize ?? 100;

			return {
				width: yup
					.number()
					.typeError(`Number between ${widthMin} to ${widthMax} required.`)
					.min(widthMin, `Too small. Minimum width (${widthMin}').`)
					.max(widthMax, `Too large. Maximum width (${widthMax}').`)
					.required(`Number between ${widthMin} to ${widthMax} required.`),

				depth: yup
					.number()
					.typeError(`Number between ${depthMin} to ${depthMax} required.`)
					.min(depthMin, `Too small. Minimum depth (${depthMin}').`)
					.max(depthMax, `Too large. Maximum depth (${depthMax}').`)
					.required(`Number between ${depthMin} to ${depthMax} required.`),
			};
		}

		const sizeMin = shapeDetail.minSize ?? shapeDetail.minWidth ?? 0;
		const sizeMax = shapeDetail.maxSize ?? shapeDetail.maxWidth ?? 100;
		return {
			size: yup
				.number()
				.typeError(`Number between ${sizeMin} to ${sizeMax} required.`)
				.min(sizeMin, `Too small. Minimum size (${sizeMin}').`)
				.max(sizeMax, `Too large. Maximum size (${sizeMax}').`)
				.required(`Number between ${sizeMin} to ${sizeMax} required.`),
		};
	};

export type FormValues = {
	readonly label: string;
	readonly id: string;
	readonly type?: string;
	readonly subtype?: Shape | undefined;
	readonly size?: number;
	readonly width?: number;
	readonly depth?: number;
	readonly fill?: string;
	readonly stroke?: string;
	readonly textSize?: TextSizes;
	readonly strokeWidth?: PathSizes;
};

type OtherProperties = {
	readonly onClose: () => void;
};

export const makeForm = ({
	store,
	onClose,
	object,
}: Readonly<{
	readonly store: Store<RootState>;
	readonly onClose: () => void;
	readonly object: EcogardenObject | EcogardenPolygon;
	// readonly onSubmit: (values: FormValues) => void;
}>): React.ComponentType<OtherProperties & FormValues> =>
	withFormik<OtherProperties & FormValues, FormValues>({
		mapPropsToValues: (properties: FormValues): FormValues => ({
			label: properties.label,
			width: properties.width,
			depth: properties.depth,
			size: properties.size,
			fill: properties.fill,
			stroke: properties.stroke,
			textSize: properties.textSize,
			strokeWidth: properties.strokeWidth,
			id: object.id,
			type: object.type,
			subtype: object.subtype,
		}),
		validationSchema: yup.object({
			label: yup.string().trim(),
			fill: yup.string().trim(),
			stroke: yup.string().trim(),
			textSize: yup.string().trim(),

			// Handle the keys we care about
			// size
			// width | depth (height)
			...sizesValidationSchema(object.type)(object.subtype),
		}),
		handleSubmit: (values) => {
			submit(store)(onClose)(values);
		},
	})(EditForm);

type Properties = {
	readonly onClose: () => void;
};

type DispatchProperty = { readonly dispatch: Dispatch };
type SelectionProperty = {
	readonly selection: EcogardenObjects;
};

/**
 * Selection Panel - Edit
 */
const Edit: React.FunctionComponent<
	Readonly<Pick<RootState, "objects">> &
		Readonly<Properties> &
		Readonly<SelectionProperty> &
		Readonly<DispatchProperty>
	// eslint-disable-next-line complexity
> = ({ objects, onClose, selection }) => {
	const store = useStore<RootState>();
	const object = findObject(objects.present)(selection.id);

	if (!object || !object.id || !object.type) {
		return <></>;
	}

	const FormikEditForm = makeForm({
		store,
		onClose,
		object: object,
	});

	// Skip textbox for size, width, depth,
	// TODO: we should make this work better to just include the shapes we need to work with
	return (
		<Box sx={{ width: "100%", zIndex: 3, fontSize: 1 }}>
			<FormikEditForm
				id={object.id}
				type={object.type}
				subtype={object.subtype}
				label={object.label ?? ""}
				depth={
					object.type !== "textbox" &&
					object.type !== "line" &&
					object.type !== "path"
						? pixelsToFeet(getScaledHeight(object))
						: undefined
				}
				width={
					object.type !== "textbox" &&
					object.type !== "line" &&
					object.type !== "path"
						? pixelsToFeet(getScaledWidth(object))
						: undefined
				}
				size={
					object.type !== "textbox" &&
					object.type !== "line" &&
					object.type !== "path"
						? pixelsToFeet(getScaledWidth(object))
						: undefined
				}
				fill={object.fill}
				stroke={object.stroke ?? ""}
				textSize={(object as EcogardenTextbox).size ?? undefined}
				strokeWidth={(object as EcogardenPath).strokeWidth ?? undefined}
				onClose={onClose}
			/>
		</Box>
	);
};

export default connect((state: Readonly<RootState>) => ({
	objects: state.objects,
}))(Edit);
