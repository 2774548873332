import type React from "react";
import { GridProps } from "theme-ui";
import { EcogardenObject, EcogardenPolygon } from "../../lib/objects";
import Labels from "./Labels";

interface Selection {
  readonly selection: readonly (EcogardenObject | EcogardenPolygon)[];
}

const GroupSelection: React.FunctionComponent<Selection & GridProps> = ({
  selection,
  ...props
}) => <Labels labels={{ mid: `${selection.length} selected` }} {...props} />;

export default GroupSelection;
