/* eslint-disable max-lines-per-function */
import styled from "@emotion/styled";
import { ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import { Box, Grid, Label, Radio } from "theme-ui";
import { EcogardenFabricTextbox } from "../../lib/fabric/objects";
import { PathSizes, sizeToNumber } from "../../lib/path";

const sizes: readonly PathSizes[] = ["small", "medium", "large"];

const ErrorBlock = styled(Box)`
  font-size: 0.8em;
  background-color: var(--theme-ui-colors-alert);
  padding: 0 4px;

  &:before {
    content: "⚠ ";
  }
`;

// readonly size: "small" | "medium" | "large";
// readonly setSize: React.Dispatch<React.SetStateAction<Sizes>>;
// readonly onSelected?: () => void;
const StrokeSize: React.FunctionComponent<{
  readonly strokeWidth?: PathSizes;
  readonly object?: EcogardenFabricTextbox;
  readonly onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ strokeWidth, object, onBlur, onChange }) => {
  const [size, setSize] = useState(strokeWidth);

  console.log("size", size, strokeWidth);

  useEffect(() => {
    if (object) {
      object.animate("strokeWidth", sizeToNumber(size), {
        duration: 140,
        onChange: () => {
          object.canvas?.requestRenderAll();
        },
      });
      object.canvas?.requestRenderAll();
    }
  });
  return (
    <Box>
      <Grid
        sx={{
          p: 2,
          gridTemplateColumns: "1fr 1fr 1fr",
          justifyContent: "center",
        }}
      >
        <Label
          sx={{
            padding: 2,
            alignItems: "center",
            backgroundColor: size === "small" ? "accent-bg" : "transparent",
            "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
            justifyContent: "center",
          }}
        >
          <Radio
            type="radio"
            name="strokeWidth"
            checked={size === "small"}
            onChange={(e) => {
              setSize("small");
              (e.target as HTMLInputElement).blur();
              onChange(e);
            }}
            value="small"
            onBlur={onBlur}
          />
          Small
        </Label>
        <Label
          sx={{
            padding: 2,
            alignItems: "center",
            backgroundColor: size === "medium" ? "accent-bg" : "transparent",
            "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
            justifyContent: "center",
          }}
        >
          <Radio
            type="radio"
            name="strokeWidth"
            checked={size === "medium" || size === undefined}
            onChange={(e) => {
              setSize("medium");
              (e.target as HTMLInputElement).blur();
              onChange(e);
            }}
            value="medium"
            onBlur={onBlur}
          />
          Medium
        </Label>
        <Label
          sx={{
            padding: 2,
            alignItems: "center",
            backgroundColor: size === "large" ? "accent-bg" : "transparent",
            "&:hover": { cursor: "pointer", backgroundColor: "accent-bg" },
            justifyContent: "center",
          }}
        >
          <Radio
            type="radio"
            name="strokeWidth"
            checked={size === "large"}
            onChange={(e) => {
              setSize("large");
              (e.target as HTMLInputElement).blur();
              onChange(e);
            }}
            value="large"
            onBlur={onBlur}
          />
          Large
        </Label>
      </Grid>
      <ErrorMessage name="textSize" component={ErrorBlock} />
    </Box>
  );
};

export default StrokeSize;
