import React from "react";
import styled from "styled-components";
import { Grid } from "theme-ui";

import InfoModal from "./InfoModal";
import IconBlock from "../buttons/IconBlock";
import Button from "../buttons/Button";

// import AlertOctagonIcon from "../icons/alert-octagon.svg";
import { AlertOctagon } from "@styled-icons/feather/AlertOctagon";
import InfoIcon from "../icons/help-circle.svg";

const Article = styled.article`
  padding: 1em;
  text-align: center;
`;

type Props = {
  readonly onRequestClose: (e?: React.FormEvent) => void;
  readonly caution?: boolean;
  readonly onConfirm: (e?: React.FormEvent) => void;
  readonly onCancel: (e?: React.FormEvent) => void;
  readonly isOpen: boolean;
  readonly actionText?: string;
  readonly actionProps?: { readonly variant: string };
};

/**
 * Modal to handle confirmations.
 *
 * Requires:
 * - onConfirm
 * - onCancel
 */
// eslint-disable-next-line max-lines-per-function
const ConfirmModal: React.FunctionComponent<React.PropsWithChildren<Props>> = (
  props
) => {
  const cancel = (): void => {
    if (props.onCancel) {
      props.onCancel();
    }
    props.onRequestClose();
  };

  return (
    <InfoModal onClose={props.onRequestClose} {...props}>
      <Grid m={3} sx={{ height: 72, justifyContent: "center" }}>
        <IconBlock
          size={72}
          frontIconProps={{
            stroke: props.caution ? "var(--alert-color)" : "currentColor",
          }}
          icon={props.caution ? AlertOctagon : InfoIcon}
        />
      </Grid>
      <Article>{props.children}</Article>
      <Grid columns={2} m={3}>
        <Button
          variant="default"
          sx={{ fontSize: 2 }}
          type="button"
          onClick={cancel}
        >
          No
        </Button>
        <Button
          sx={{ fontSize: 2 }}
          type="button"
          onClick={props.onConfirm}
          {...props.actionProps}
        >
          {props.actionText ?? "Yes"}
        </Button>
      </Grid>
    </InfoModal>
  );
};

export default ConfirmModal;
