/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import emotionStyled from "@emotion/styled";
import { fabric } from "fabric";
import Mousetrap, { ExtendedKeyboardEvent, MousetrapInstance } from "mousetrap";
import React, { useEffect, useMemo, useState } from "react";
import { Box, Grid } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import { useAppSelector } from "../../lib/configureStore";
// import { applyControls } from "../../lib/controls/fabric/onlyrotate";
import { applyControls as applyScaleRotateControls } from "../../lib/controls/fabric/scalerotate";
import { applyControls as applyDefaultControls } from "../../lib/controls/fabric/default";
import { applyControls as applyLineControls } from "../../lib/controls/fabric/line";
import { applyControls as applyPolygonControls } from "../../lib/controls/fabric/polygon";
import { applyControls as textboxControls } from "../../lib/controls/fabric/textbox";
import { Direction, findDirectionFromAction } from "../../lib/direction";
import {
  EcogardenCanvas,
  FabricEventHandler,
  fadeOutExcept,
} from "../../lib/fabric";
import type { SelectableFabricCanvas } from "../../lib/fabric/canvas";
import { layerLockVisibleFilter } from "../../lib/fabric/layers";
import {
  EcogardenFabricObject,
  EcogardenFabricObjects,
  EcogardenFabricPolygon,
  EcogardenFabricTextbox,
  moveObject,
} from "../../lib/fabric/objects";
import {
  EcogardenObject,
  EcogardenObjects,
  EcogardenPolygon,
  toEcogardenObject,
} from "../../lib/objects";
import {
  isCircle,
  isCustomShape,
  isLine,
  isTextbox,
  LayersState,
  Shape,
} from "../../shapes";
import CopyTool from "../tools/CopyTool";
import SelectionPanel from "./SelectionPanel";

const Container = emotionStyled(Grid)`
  justify-self: center;
  z-index: 3;
  pointer-events: none;
`;

/** Mousetrap Event Handler */
type MousetrapEventHandler = (
  opt: ExtendedKeyboardEvent,
  action?: string
) => void;

/** Bind fabric.Canvas to Fabric Event Handler */
type CanvasFabricEventHandler = (
  canvas: SelectableFabricCanvas
) => FabricEventHandler;

type DispatchSelectionState = React.Dispatch<
  React.SetStateAction<readonly (EcogardenObject | EcogardenPolygon)[]>
>;

/** Bind setState to Canvas Event Handler */
type StatefulCanvasFabricEventHandler = (
  setState: React.Dispatch<
    React.SetStateAction<readonly (EcogardenObject | EcogardenPolygon)[]>
  >
) => CanvasFabricEventHandler;

/** Bind fabric.Canvas to Mousetrap Event Handler */
type CanvasMousetrapEventHandler = (
  canvas: SelectableFabricCanvas
) => MousetrapEventHandler;

/** Fabric (mouse) or Mousetrap (keyboard) Event Handler */
// type ControlEventHandler = FabricEventHandler | MousetrapEventHandler;

/** Bind fabric.Canvas bound Control Event Handler (mouse, keyboard) */
type CanvasEventHandler = (canvas: fabric.Canvas) => () => void;

/**
 * Bind canvas to event handlers
 */
const bind =
  (canvas: SelectableFabricCanvas) =>
  (
    events: readonly (readonly [string, CanvasFabricEventHandler])[]
  ): readonly (readonly [string, FabricEventHandler])[] =>
    events.map(([key, handler]) => [key, handler(canvas)]);

/**
 * Move selected objects on the canvas using key commands
 */
const objectMove: CanvasMousetrapEventHandler =
  (canvas) =>
  (_, action): void => {
    // No action, don't move anywhere.
    if (!action) {
      return;
    }

    const direction = findDirectionFromAction(action as Direction);

    // No valid direction
    if (!direction) {
      return;
    }

    const selection = new fabric.Group(canvas.getActiveObjects());

    moveObject(selection)(direction)(5);
    canvas.requestRenderAll();
  };

/**
 * Event Handler when selection is updated
 */
const updated: StatefulCanvasFabricEventHandler =
  (setState) => (canvas) => async (): Promise<void> => {
    const selectedObjects: readonly EcogardenFabricObject[] =
      canvas.getActiveObjects() as readonly EcogardenFabricObject[];

    const selected = canvas.getActiveObject();

    // Apply rotation controls
    if (isTextbox(selected.subtype as Shape)) {
      textboxControls(selected);
    } else if (isCustomShape(selected.subtype as Shape)) {
      applyPolygonControls(selected as EcogardenFabricPolygon);
    } else if (isLine(selected.subtype as Shape)) {
      selected.hasBorders = true;

      // We turn off per pixel targeting for easier moving of a
      // small thin item like a line but to select again we
      // require precision because the bounding box of the line can be very large
      selected.perPixelTargetFind = false;
      applyLineControls(selected as fabric.Line);
    } else if (isCircle(selected.subtype as Shape | undefined)) {
      applyScaleRotateControls({})(selected);
    } else if (selected.type == "activeSelection") {
      applyScaleRotateControls({})(selected);
    } else {
      applyDefaultControls(selected);
    }

    fadeOutExcept(
      (canvas.getObjects() as readonly EcogardenFabricObjects[]).filter(
        // disable fading out polygon and textbox as they are usually not blocking things
        (shape) => shape.type !== "polygon" && shape.type !== "textbox"
      ),
      selectedObjects.filter((shape) => shape.id).map((shape) => shape.id ?? "")
    );

    setState(
      findObjects(selectedObjects.map(toEcogardenObject))(
        selectedObjects
          .map(({ id }) => id)
          .filter((id) => id !== undefined) as readonly string[]
      )
    );
  };

/**
 * Event Handler when selection is created
 */
const created: StatefulCanvasFabricEventHandler =
  (setState) => (canvas) => async (): Promise<void> => {
    const selectedObjects: readonly EcogardenFabricObject[] =
      canvas.getActiveObjects() as readonly EcogardenFabricObject[];
    const selected = canvas.getActiveObject();

    // Apply controls to selected objects
    if (isTextbox(selected.subtype as Shape)) {
      textboxControls(selected);
    } else if (isCustomShape(selected.subtype as Shape | undefined)) {
      applyPolygonControls(selected as EcogardenFabricPolygon);
    } else if (isLine(selected.subtype as Shape | undefined)) {
      selected.hasBorders = true;

      // We turn off per pixel targeting for easier moving of a
      // small thin item like a line but to select again we
      // require precision because the bounding box of the line can be very large
      selected.perPixelTargetFind = false;
      applyLineControls(selected as fabric.Line);
    } else if (isCircle(selected.subtype as Shape | undefined)) {
      applyScaleRotateControls({})(selected);
    } else if (selected.type == "activeSelection") {
      applyScaleRotateControls({})(selected);
    } else {
      applyDefaultControls(selected);
    }

    canvas.requestRenderAll();

    fadeOutExcept(
      (canvas.getObjects() as readonly EcogardenFabricObjects[]).filter(
        // disable fading out polygon and textbox as they are usually not blocking things
        (shape) => shape.type !== "polygon" && shape.type !== "textbox"
      ),
      selectedObjects.map((shape) => shape.id ?? "")
    );

    // selectedObjects.forEach(applyGlow);

    setState(
      findObjects(selectedObjects.map(toEcogardenObject))(
        selectedObjects
          .map(({ id }) => id)
          .filter((id) => id !== undefined) as readonly string[]
      )
    );
  };
const findObjects =
  (objects: readonly (EcogardenObject | EcogardenPolygon)[]) =>
  (ids: readonly string[]): readonly (EcogardenObject | EcogardenPolygon)[] => {
    return ids
      .flatMap((id) => objects.find((object) => object.id === id) ?? [])
      .filter((o) => o);
  };
/**
 * Selection is cleared event handler
 */

type ClearedHandler = (
  setState: DispatchSelectionState
) => (layers: LayersState) => (canvas: EcogardenCanvas) => () => void;

const cleared: ClearedHandler =
  (setState) => (layers) => (canvas) => async () => {
    canvas.forEachObject((o) => {
      [layerLockVisibleFilter(layers)].forEach((f) => f(o));
      // We turn off per pixel targeting for easier moving of a
      // small thin item like a line but to select again we
      // require precision because the bounding box of the line can be very large
      if (isLine(o.subtype as Shape | undefined)) {
        o.perPixelTargetFind = true;
      }
    });
    setState([]);
  };

/**
 * Clear canvas selection
 */
const clearSelection: CanvasEventHandler = (canvas) => (): void => {
  if (!canvas) {
    return;
  }

  canvas.discardActiveObject();
  canvas.requestRenderAll();
};

/**
 * Enable event handlers to mousetrap
 */
const setupKeyboardEvents =
  (mousetrap: MousetrapInstance) =>
  (canvas: EcogardenCanvas) =>
  (
    events: readonly (readonly [string, CanvasFabricEventHandler])[]
  ): (() => void) => {
    mousetrap.bind(["up", "down", "left", "right"], objectMove(canvas));
    mousetrap.bind("escape", clearSelection(canvas));

    const canvasEvents = bind(canvas)(events);

    canvasEvents.map(([key, handler]) => canvas.on(key, handler));

    return function cleanup(): void {
      canvasEvents.map(([key, handler]) => canvas.off(key, handler));

      mousetrap.unbind(["up", "down", "left", "right"]);
    };
  };

const editing = (e: fabric.IEvent): void => {
  const target = e.target as EcogardenFabricTextbox;

  if ("hiddenTextarea" in target) {
    target.hiddenTextarea?.select();
  }
};

/**
 * Selection container
 */
// eslint-disable-next-line max-lines-per-function
const SelectionContainer: React.FunctionComponent = () => {
  const [selection, setSelection] = useState<readonly EcogardenObjects[]>([]);
  const mousetrap = useMemo<MousetrapInstance>(() => new Mousetrap(), []);
  const { layers, canvas } = useAppSelector((state) => ({
    layers: state.layers,
    canvas: getCanvas(state.canvas) as EcogardenCanvas,
  }));

  const events: readonly (readonly [string, CanvasFabricEventHandler])[] =
    useMemo(
      () => [
        ["selection:created", created(setSelection)],
        ["selection:updated", updated(setSelection)],
        ["selection:cleared", cleared(setSelection)(layers)],
        ["text:editing:entered", () => () => editing],
      ],
      [layers]
    );

  useEffect(() => {
    if (!canvas) {
      return;
    }

    return setupKeyboardEvents(mousetrap)(canvas)(events);
  }, [canvas, events, mousetrap]);

  if (!canvas || !selection || selection.length === 0) {
    return (
      <Box
        data-testid="no-selection"
        className="selectionPanel-no-selection"
        sx={{ gridArea: "selection", pointerEvents: "none" }}
      ></Box>
    );
  }

  return (
    <Container
      sx={{
        alignItems: "start",
        gridArea: "selection",
        gridAutoFlow: ["row", "column"],
        maxHeight: ["calc(var(--vh, 1vh) * 50)", "calc(var(--vh, 1vh) * 75)"],
      }}
    >
      <SelectionPanel
        selection={selection}
        onCancelRequest={clearSelection(canvas)}
      />
    </Container>
  );
};

export default SelectionContainer;
