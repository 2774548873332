/* eslint-disable max-lines */
/* eslint-disable complexity */
import { Eye } from "@styled-icons/feather/Eye";
import { Lock } from "@styled-icons/feather/Lock";
import Tippy from "@tippyjs/react";
import { ErrorMessage, FormikProps } from "formik";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Field, Flex, Grid } from "theme-ui";
import { modifiedObject } from "../../actions/objects";
import { getCanvas } from "../../design/canvas";
import { RootState, useAppSelector } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import {
	EcogardenFabricTextbox,
	findAndSyncSizes,
	findAndSyncStroke,
	findAndSyncText,
} from "../../lib/fabric/objects";
import log from "../../lib/log";
import * as ecogardenObjects from "../../lib/objects";
import { capitalize } from "../../lib/string";
import { sizeToNumber } from "../../lib/text";
import { getAvailableSizes, getMaxSize, getMinSize } from "../../shapes";
import Fill from "../canvas/Fill";
import Stroke from "../canvas/Stroke";
import { FormValues } from "./Edit";
import EditSize from "./EditSize";
import StrokeSize from "./StrokeSize";
import TextSize from "./TextSize";

const ConfirmBar: React.FunctionComponent<{
	readonly id: string;
	readonly onCancel: () => void;
	readonly onSubmit: () => void;
	readonly isSubmitting: boolean;
	// eslint-disable-next-line max-lines-per-function
}> = ({ id, onCancel, onSubmit, isSubmitting }) => {
	const [objectLock, objectVisible, objects] = useAppSelector((state) => {
		return [
			state.features["object-lock"] ?? false,
			state.features["object-visible"] ?? false,
			state.objects.present,
		];
	});
	const dispatch = useDispatch();
	const optionalProps: { disabled?: boolean } = {};
	if (isSubmitting) {
		optionalProps.disabled = true;
	}

	return (
		<Grid
			p={2}
			gap={3}
			bg="accent-bg"
			sx={{
				gridTemplateColumns: "repeat(4, minmax(5em, 1fr))",
				justifyContent: "flex-end",
				borderBottomLeftRadius: [0, "0.8em"],
				fontSize: [0, 0, 1],
			}}
		>
			{objectVisible ? (
				<Tippy
					content="Toggle visibility of this object"
					appendTo={document.querySelector("#tooltips-container") ?? undefined}
				>
					<Button
						variant="default"
						type="button"
						onClick={() => {
							dispatch(
								modifiedObject({
									id: id,
									visible:
										objects.find((obj) => obj.id === id)?.visible === true
											? false
											: true,
								})
							);
						}}
						sx={
							objects.find((obj) => obj.id === id)?.visible === false
								? {
										backgroundColor: "red",
								  }
								: {}
						}
					>
						<Eye width={24} height={24} />
						Visible
					</Button>
				</Tippy>
			) : (
				<div></div>
			)}
			{objectLock ? (
				<Tippy
					content="Lock this object"
					appendTo={document.querySelector("#tooltips-container") ?? undefined}
				>
					<Button
						variant="default"
						type="button"
						title="Lock this object"
						onClick={() => {
							dispatch(
								modifiedObject({
									id: id,
									lock: objects.find((obj) => obj.id === id)?.lock
										? false
										: true,
								})
							);
						}}
						sx={
							objects.find((obj) => obj.id === id)?.lock === true
								? {
										backgroundColor: "red",
								  }
								: {}
						}
					>
						<Lock width={24} height={24} />
						Lock
					</Button>
				</Tippy>
			) : (
				<div></div>
			)}
			<Button
				variant="default"
				type="button"
				onClick={onCancel}
				aria-label="Cancel"
			>
				Cancel
			</Button>
			<Button
				variant="primary"
				onClick={onSubmit}
				type="submit"
				aria-label="Save"
				{...optionalProps}
			>
				Save
			</Button>
		</Grid>
	);
};

// const syncFontSize = (canvas: (canvas.getObjects())(objects)

const handleEditCancel =
	(canvas: EcogardenCanvas | undefined) =>
	(objects: readonly ecogardenObjects.EcogardenObjects[]) =>
	(onClose: () => void) =>
	(id: string | undefined) =>
	(): void => {
		if (canvas) {
			findAndSyncSizes(canvas.getObjects())(objects)(id);
			findAndSyncText(canvas.getObjects())(objects)(id);
			findAndSyncStroke(canvas.getObjects())(objects)(id);
		}

		onClose();
	};

type OtherProperties = {
	readonly onClose: () => void;
};

const EditForm: React.FunctionComponent<
	Readonly<OtherProperties & FormikProps<FormValues>>
	// eslint-disable-next-line max-lines-per-function
> = ({
	onClose,
	values,
	touched,
	errors,
	handleChange,
	handleBlur,
	handleSubmit,
	setFieldValue,
	isSubmitting,
}) => {
	// length is in errors and we are checking for it
	// @ts-ignore
	if ("length" in errors && errors.length > 0) {
		log.debug("errors in edit form", errors);
	}
	const sizes = useMemo(
		() => getAvailableSizes(values.subtype),
		[values.subtype]
	);
	const { strokeColor, accentColor, canvas, objects } = useAppSelector(
		(state: Readonly<RootState>) => ({
			canvas: getCanvas(state.canvas) as EcogardenCanvas | undefined,
			objects: state.objects,
			accentColor: state.features["accent-color"] ?? false,
			strokeColor: state.features["stroke-color"] ?? false,
		})
	);

	return (
		<form id="edit-form" onSubmit={handleSubmit}>
			<input type="hidden" name="id" value={values.id} />
			{values.type === "textbox" ? (
				<TextSize
					textSize={values.textSize}
					object={
						canvas?.getObjects().find(({ id }) => id === values.id) as
							| EcogardenFabricTextbox
							| undefined
					}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			) : values.type === "path" || values.type === "line" ? (
				<StrokeSize
					strokeWidth={values.strokeWidth}
					object={
						canvas?.getObjects().find(({ id }) => id === values.id) as
							| EcogardenFabricTextbox
							| undefined
					}
					onChange={handleChange}
					onBlur={handleBlur}
				/>
			) : values.type === "polygon" ? (
				<></>
			) : (
				sizes.map((key) => {
					return (
						<EditSize
							key={key}
							min={getMinSize(values.subtype)(key)}
							max={getMaxSize(values.subtype)(key)}
							objectId={values.id}
							name={key}
							label={`${capitalize(key)} (in feet)`}
							onChange={handleChange}
							onBlur={handleBlur}
							error={errors[key]}
							touched={touched[key] ?? false}
							// values[key] ideally is a string or number but we want it to be limited to 3 decimals, to which it will be a fixed depth of [3].[3] 6
							value={
								key in values && values[key] !== undefined
									? typeof values[key] === "number"
										? (values[key] as number).toFixed(3)
										: (values[key] as unknown as string)
									: getMinSize(values.subtype)(key).toFixed(3)
							}
							initialTouched={false}
							setFieldValue={setFieldValue}
						/>
					);
				})
			)}
			{values.type === "textbox" || values.type === "line" ? (
				<></>
			) : (
				<Grid
					className="mobile-text-fix"
					sx={{ gridTemplateColumns: "1fr auto", alignItems: "center" }}
				>
					<ErrorMessage name="label" />
					<Field
						p={2}
						mx={2}
						marginBottom={2}
						name="label"
						label="Label"
						placeholder="Large Frontyard Tree"
						type="text"
						id="edit-label"
						maxLength={70}
						value={values.label}
						onChange={handleChange}
						onBlur={handleBlur}
					/>
					<Box sx={{ display: "none", p: 2 }} className="done">
						<Button
							variant="default"
							type="button"
							onClick={(e) => {
								(
									document.querySelector("#edit-label") as
										| HTMLInputElement
										| undefined
								)?.blur();
							}}
						>
							Done
						</Button>
					</Box>
				</Grid>
			)}
			{strokeColor && values.type && ["path", "line"].includes(values.type) && (
				<Box sx={{ padding: 2 }}>
					<ErrorMessage name="stroke" />
					<Stroke
						id={values.id}
						value={values.stroke}
						handleChange={handleChange}
						handleBlur={handleBlur}
						touched={touched["stroke"] ?? false}
						initialTouched={false}
					/>
				</Box>
			)}
			{accentColor && values.type && ["group"].includes(values.type) && (
				<Box sx={{ padding: 2 }}>
					<ErrorMessage name="fill" />
					<Fill
						id={values.id}
						value={values.fill}
						handleChange={handleChange}
						handleBlur={handleBlur}
						touched={touched["fill"] ?? false}
						initialTouched={false}
					/>
				</Box>
			)}
			<ConfirmBar
				id={values.id}
				onCancel={handleEditCancel(canvas)(objects.present)(onClose)(values.id)}
				onSubmit={handleSubmit}
				isSubmitting={isSubmitting}
			/>
		</form>
	);
};

export default EditForm;
