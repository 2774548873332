/* eslint-disable complexity */
import { ChevronDown } from "@styled-icons/feather/ChevronDown";
import React, { useState } from "react";
import { Box, Button, Flex, Grid, StylePropertyValue } from "theme-ui";
import "../../../css/floating.css";
import slideDown from "../../lib/animations/slidedown";
import type { RootState } from "../../lib/configureStore";
import { EcogardenObjects } from "../../lib/objects";
import slideUp from "../shapes/ShapesPanel";
import CopyTool from "../tools/CopyTool";
// Components
import Controls from "./Controls";
import Edit from "./Edit";
import Title from "./Title";

type Requests = {
	readonly onCancelRequest: () => void;
};

export type EcogardenSelectionProperty = {
	readonly selection: readonly EcogardenObjects[];
};

/**
 * Selection Panel Component
 */
const SelectionPanel: React.FunctionComponent<
	Requests & EcogardenSelectionProperty & Partial<RootState>
	// eslint-disable-next-line max-lines-per-function
> = ({ onCancelRequest, selection }) => {
	const [showEdit, setShowEdit] = useState(false);
	const sxOnEdit = showEdit ? { overflow: "auto" } : {};

	return (
		<Box
			className="selection-panel"
			sx={{
				bg: "background",

				border: "panel",
				borderRadius: ["panelBottom", "panelTop"],
				borderWidth: [0, 2],
				borderTopWidth: [2, 0],
				boxShadow: "panel",

				pointerEvents: "all",
				// overflowY: "auto",
				maxHeight: "calc(var(--vh, 1vh) * 80)",
				width: ["100vw", "50vw"],
				// width: ["100vw", ""],
				maxWidth: ["100%", "26em"],
				"@media (max-height: 500px)": {
					maxHeight: "calc(var(--vh, 1vh) * 80)",
					fontSize: 1,
					animation: `96ms ease-out ${slideUp}`,
				},

				"@media (max-height: 400px)": {
					maxHeight: "calc(var(--vh, 1vh) * 70)",
				},
				"@media (max-height: 360px)": {
					// maxHeight: "calc(var(--vh, 1vh) * 70)",
					...(showEdit ? { height: "100%" } : {}),
				},
				"@media (max-height: 320px)": {
					maxHeight: "calc(var(--vh, 1vh) * 70)",
					...(showEdit ? { height: "100%" } : {}),
				},
				"@media (max-height: 260px)": {
					maxHeight: "calc(var(--vh, 1vh) * 100)",
				},
				animation: [`96ms ease-out ${slideUp}`, `96ms ease-out ${slideDown}`],
				zIndex: 3,
				...sxOnEdit,
				"@media (max-height: 150px)": {
					height: "calc(var(--vh, 1vh) * 100)",
					// If we are on a very small screen, with overflow being auto or scroll the elements disappear.
					// I can not explain this behavior so we just remove the overflow on thse smaller sizes.
					// This happens when entering a field with a input and the on screen keyboard takes up 85% of the screen
					overflow: "initial",
				},
			}}
		>
			<Grid
				className="selection-panel-title"
				as="section"
				sx={{
					justifyContent: "space-between",
					gridTemplateColumns: "1fr 44px 44px",
					gridGap: 3,
				}}
				p={2}
			>
				<Title
					onClick={() => setShowEdit((v) => (v ? false : true))}
					selection={selection}
				/>

				<CopyTool hasSelection={true} />
				<Controls onCancelRequest={onCancelRequest} />
			</Grid>

			{!showEdit && (
				<Grid
					sx={{
						height: 0,
						justifyContent: "center",
						lineHeight: 1,
						transform: "translateY(-8px)",
						position: "relative",
						zIndex: 3,
					}}
				>
					<Button
						onClick={() => setShowEdit(showEdit ? false : true)}
						sx={{
							border: "none",
							boxShadow: "0 0 2px var(--accent-fg-color)",
							backgroundColor: "background",
							height: 24,
							opacity: 0.8,
							transition: "all ease-in-out 96ms",
							"&:hover,&:focus": {
								opacity: 1,
							},
						}}
						title={`Edit ${
							selection.length === 1
								? selection[0].label ?? selection[0].subtype
								: "Selection"
						}`}
					>
						<ChevronDown
							width="24"
							height="24"
							strokeWidth={4}
							sx={{ pointerEvents: "none" }}
						/>
					</Button>
				</Grid>
			)}
			<Grid as="section">
				{showEdit && selection.length === 1 && (
					<Edit
						selection={selection[0]}
						onClose={(): void => setShowEdit(false)}
					/>
				)}
			</Grid>
		</Box>
	);
};

export default SelectionPanel;
