import React, { useState } from "react";
import styled from "styled-components";
import Modal from "styled-react-modal";
import { Box } from "theme-ui";
import MenuHeader from "../MenuHeader";

interface ModalProps {
	readonly opacity: number;
}

const StyledModal = styled(Modal)`
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${(props: ModalProps): number => props.opacity};
	transition: opacity ease 500ms;
`;

type EventHandler = () => void | Promise<() => void>;
type StateEventHandler = (
	setState: React.Dispatch<React.SetStateAction<number>>
) => EventHandler;

const afterOpen: StateEventHandler = (setState) => (): void => {
	setTimeout(() => {
		setState(1);
	}, 96);
};

export type HandlerProps = {
	readonly onClose: () => void;
	readonly onBackRequest?: () => void;
};

export type InfoProps = {
	readonly title?: string;
	readonly isOpen: boolean;
	readonly children?: React.ReactNode;
};

const InfoModal: React.FunctionComponent<
	InfoProps & HandlerProps
	// eslint-disable-next-line max-lines-per-function
> = ({ children, isOpen, title, onClose, onBackRequest, ...props }) => {
	const [opacity, setOpacity] = useState(1);

	return (
		<StyledModal
			isOpen={isOpen}
			opacity={opacity}
			onBackgroundClick={onClose}
			afterOpen={afterOpen(setOpacity)}
			{...props}
		>
			<Box
				bg="background"
				sx={{
					borderRadius: "primary",
					border: "panel",
					boxShadow: "panel",
					minWidth: "18em",
					maxWidth: "40em",
					maxHeight: "calc(var(--vh, 1vh) * 100)",
					overflow: "auto",
				}}
			>
				{title ? (
					<MenuHeader onBackRequest={onBackRequest} onCloseRequest={onClose}>
						{title}
					</MenuHeader>
				) : (
					""
				)}

				{children}
			</Box>
		</StyledModal>
	);
};

export default InfoModal;
