/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Eye } from "@styled-icons/feather/Eye";
import { EyeOff } from "@styled-icons/feather/EyeOff";
import { Lock } from "@styled-icons/feather/Lock";
import { Unlock } from "@styled-icons/feather/Unlock";
import Tippy from "@tippyjs/react";
import { useDispatch } from "react-redux";
import { Box, Button, Grid } from "theme-ui";
import { modifiedObject } from "../../actions/objects";
import { getCanvas } from "../../design/canvas";
import { useAppSelector } from "../../lib/configureStore";
import { EcogardenCanvas, fadeOutExcept } from "../../lib/fabric";
import { EcogardenObjects } from "../../lib/objects";
import { capitalize, dashesToSpaces } from "../../lib/string";
import { findShapeLayer, getShapeSVGFile, Layer } from "../../shapes";
import IconButton from "../buttons/IconButton";
import MenuHeader from "../MenuHeader";

const ObjectsList: React.FunctionComponent<{
  readonly onCloseRequest?: React.MouseEventHandler<Element>;
}> = ({ onCloseRequest }) => {
  const [objects, canvas] = useAppSelector((state) => {
    return [
      state.objects.present,
      getCanvas(state.canvas) as EcogardenCanvas | undefined,
    ];
  });
  const dispatch = useDispatch();
  return (
    <Grid
      sx={{
        fontSize: [1, 1],
        position: "absolute",
        right: 0,
        marginX: 2,
        pointerEvents: "all",
        backgroundColor: "background",
        borderRadius: "primary",
        border: "panel",
        gridGap: 1,
      }}
    >
      <MenuHeader onCloseRequest={onCloseRequest}>Objects list</MenuHeader>
      <Box sx={{ paddingX: 2 }}>{objects.length} objects</Box>
      <Grid
        sx={{
          maxHeight: "calc(var(--vh, 1vh) * 50)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {Object.entries(
          objects.reduce(
            (results, object) => {
              const layer = findShapeLayer(object.subtype);
              if (!layer) {
                return results;
              }

              if (!results[layer]) {
                results[layer] = [];
              }
              results[layer].push(object);

              return results;
            },
            // eslint-disable-next-line functional/prefer-readonly-type
            {} as {
              // eslint-disable-next-line functional/prefer-readonly-type
              [K in Layer]: EcogardenObjects[];
            }
          )
        ).map(([layer, ecoObjects]) => {
          return (
            <>
              <Box
                sx={{ backgroundColor: "accent-bg", paddingX: 2, opacity: 0.8 }}
              >
                {layer} <span>({ecoObjects.length})</span>
              </Box>
              <Grid>
                {ecoObjects.map((object) => {
                  return (
                    <Grid
                      key={object.id}
                      sx={{
                        paddingX: 2,
                        gridTemplateColumns: "44px minmax(8em, 1fr) 44px 44px",
                        alignItems: "center",
                        gridGap: 3,
                      }}
                    >
                      <Box sx={{ maxWidth: 44 }}>
                        <Tippy content={object.subtype}>
                          <Button
                            variant="default"
                            sx={{
                              minWidth: "3em",
                              padding: 0,
                            }}
                            onClick={() => {
                              if (!canvas) {
                                return;
                              }
                              const fabricObject = canvas
                                .getObjects()
                                .find(({ id }) => object.id === id);

                              if (!fabricObject) {
                                return;
                              }
                              canvas.setActiveObject(fabricObject);
                              fadeOutExcept(canvas.getObjects(), [object.id]);
                              if (
                                fabricObject.left &&
                                fabricObject.top &&
                                fabricObject.width &&
                                fabricObject.height &&
                                canvas.width &&
                                canvas.height &&
                                canvas.viewportTransform
                              ) {
                                /**
                                // const tl = canvas.calcViewportBoundaries().tl
                                console.log(
                                  "top.left",
                                  canvas.calcViewportBoundaries().tl
                                );
                                console.log("zoom to point", canvas.getZoom());
                                console.log("point", {
                                  x: fabricObject.left,
                                  y: fabricObject.top,
                                });
                                // canvas.zoomToPoint(
                                //   { x: fabricObject.left, y: fabricObject.top },
                                //   canvas.getZoom()
                                // );
                                const topLeft = {
                                  x:
                                    fabricObject.left +
                                    fabricObject.width / 2 -
                                    canvas.width / canvas.getZoom() / 2,
                                  y:
                                    fabricObject.top +
                                    fabricObject.height / 2 -
                                    canvas.width / canvas.getZoom() / 2,
                                };
                                console.log(topLeft);
                                canvas.absolutePan(topLeft);
                                console.log(
                                  "setViewportTransform",
                                  canvas.viewportTransform
                                );

                                if (canvas.viewportTransform) {
                                  dispatch(
                                    viewportActions.updateViewport(
                                      canvas.viewportTransform as unknown as viewportActions.Viewport
                                    )
                                  );
                                }
																*/
                              }

                              // dispatch(zoomActions.zoom(fabricZoomLevelToOSMZoomLevel(canvas.getZoom())));
                              canvas.requestRenderAll();
                            }}
                          >
                            <img
                              src={
                                object.subtype
                                  ? getShapeSVGFile(object.subtype)
                                  : ""
                              }
                              sx={{ width: "100%" }}
                              alt={
                                object.subtype
                                  ? `Select  ${capitalize(
                                      dashesToSpaces(object.subtype)
                                    )}`
                                  : "Select this shape"
                              }
                            />
                          </Button>
                        </Tippy>
                      </Box>
                      <Box sx={{ fontSize: [0, 1], lineHeight: 1.2 }}>
                        <Box>{object.label ? object.label : ""}</Box>
                        <Box>
                          {object.subtype
                            ? capitalize(dashesToSpaces(object.subtype))
                            : ""}
                        </Box>
                      </Box>
                      <Box>
                        {object.visible === true ||
                        object.visible === undefined ? (
                          <IconButton
                            icon={Eye}
                            label="Toggle visibility"
                            onClick={() => {
                              dispatch(
                                modifiedObject({
                                  id: object.id,
                                  visible: false,
                                })
                              );
                            }}
                          />
                        ) : (
                          <IconButton
                            icon={EyeOff}
                            label="Toggle visibility"
                            onClick={() => {
                              dispatch(
                                modifiedObject({
                                  id: object.id,
                                  visible: true,
                                })
                              );
                            }}
                          />
                        )}
                      </Box>
                      <Box>
                        {object.lock ? (
                          <IconButton
                            icon={Lock}
                            label="Toggle interactivity"
                            onClick={() => {
                              dispatch(
                                modifiedObject({
                                  id: object.id,
                                  lock: object.lock === true ? false : true,
                                })
                              );
                            }}
                          />
                        ) : (
                          <IconButton
                            icon={Unlock}
                            label="Toggle interactivity"
                            onClick={() => {
                              dispatch(
                                modifiedObject({
                                  id: object.id,
                                  lock: object.lock === true ? false : true,
                                })
                              );
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ObjectsList;
