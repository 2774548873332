import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { GridProps } from "theme-ui";
import { getCanvas } from "../../design/canvas";
import type { RootState } from "../../lib/configureStore";
import { EcogardenFabricObjects } from "../../lib/fabric/objects";
import {
	addSize,
	EcogardenObject,
	EcogardenObjects,
	findObject,
} from "../../lib/objects";
import { capitalize, dashesToSpaces } from "../../lib/string";
import Labels from "./Labels";

type LabelOptions = {
	readonly label?: string;
	readonly type?: string;
	readonly subtype?: string;
};

// t = type, l = label, p = plant
// t
// l p t l
// p t   t

/**
 * Find top Label
 */
const top = (): string => "";

/**
 * Find middle Label
 */
const middle = ({ label, subtype }: LabelOptions): string => {
	if (label) {
		return label;
	}

	if (subtype) {
		return capitalize(dashesToSpaces(subtype));
	}

	return "";
};

/**
 * Find bottom label
 */
const bottom = ({ label, subtype }: LabelOptions): string => {
	if (label && subtype) {
		return capitalize(dashesToSpaces(subtype));
	}

	if (!label) {
		return "";
	}

	return label;
};

type LabelParts = {
	readonly top: string;
	readonly mid: string;
	readonly size: string;
	readonly bottom: string;
};

const composeLabel: (
	object?: Readonly<EcogardenObjects | EcogardenFabricObjects>
) => LabelParts = (object) => {
	if (!object) {
		return { top: "", mid: "", size: "", bottom: "" };
	}

	return {
		top: top(),
		mid: middle(object),
		size: addSize(object),
		bottom: bottom(object),
	};
};

interface SelectionProperty {
	readonly selection: EcogardenObjects;
}

// let prevObjects: readonly EcogardenObject[] | undefined = undefined

/**
 * Find the object by id and then find the size
 */
const findSize = (objects: readonly EcogardenObject[]) => (id: string) => {
	const object = findObject(objects)(id);
	if (!object) {
		return undefined;
	}

	return addSize(object);
};

/**
 * Selection Panel - Plant Selection
 */
const PlantSelection: React.FunctionComponent<
	Pick<RootState, "objects"> & { readonly dispatch?: Dispatch } & {
		readonly canvas?: fabric.Canvas;
	} & SelectionProperty &
		GridProps
	// eslint-disable-next-line max-lines-per-function
> = ({ selection, objects, onClick, canvas, dispatch, ...properties }) => {
	const [labels, setLabels] = useState(composeLabel(selection));
	const [size, setSize] = useState(findSize(objects.present)(selection.id));

	useEffect(() => {
		// Update labels anyone an object is updated.
		// This is shown on selection so this would change based
		// on values in the edit or positions changed
		setLabels(composeLabel(findObject(objects.present)(selection.id)));
	}, [selection.id, objects]);

	// Update size based on the canvas scaling changing
	useEffect(() => {
		if (!canvas) {
			return;
		}
		// keep track of scaling changes in the canvas
		const onScaling = (event: fabric.IEvent) => {
			if (!event.target) {
				return;
			}
			setSize(addSize(event.target));
		};

		// const sel = canvas.getActiveObject();
		canvas.on("object:scaling", onScaling);

		return function cleanup() {
			canvas.off("object:scaling", onScaling);
		};
	}, [canvas]);

	// Update size based on the selection changing
	useEffect(() => {
		setSize(findSize(objects.present)(selection.id));
	}, [objects.present, selection]);

	return (
		<Labels labels={labels} size={size} onClick={onClick} {...properties} />
	);
};

export default connect((state: RootState) => ({
	canvas: getCanvas(state.canvas),
	objects: state.objects,
}))(PlantSelection);
