import { X } from "@styled-icons/feather";
import IconButton from "../buttons/IconButton";

type ButtonControl = React.FunctionComponent<{
  readonly onClick: React.MouseEventHandler;
  readonly selection?: readonly fabric.Object[];
  readonly showEdit?: boolean;
}>;

const Deselect: ButtonControl = ({ onClick, ...properties }) => (
  <IconButton
    label="Deselect"
    tippyProps={{ placement: "right" }}
    icon={X}
    iconProps={{ strokeWidth: 2 }}
    onClick={onClick}
    {...properties}
  />
);

export default Deselect;
