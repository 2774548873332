import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getCanvas } from "../../design/canvas";
import { RootState } from "../../lib/configureStore";
import { EcogardenCanvas } from "../../lib/fabric";
import { fabric } from "fabric";
import { dropShadow } from "../../lib/fabric/shadows";
import { EcogardenFabricObjects } from "../../lib/fabric/objects";
import { sortCanvasObjects } from "../../shapes";

const prefersDarkColorscheme = () =>
  "matchMedia" in window &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const findRelativeScale = (obj: EcogardenFabricObjects) => {
  let scale = 1;
  if (obj.width && obj.height && obj.scaleX && obj.scaleY) {
    scale = Math.max(
      (obj.width * obj.scaleX) / 300,
      (obj.height * obj.scaleY) / 300
    );
  }

  return scale;
};

const addLabel = (obj: EcogardenFabricObjects) => {
  const scale = findRelativeScale(obj);
  const label = new fabric.Textbox(obj.label as string, {
    hiddenTextareaContainer: document.getElementById("hiddenTextarea"),
    top: obj.top,
    left: obj.left,
    width: obj.width ? Math.min(300, obj.width) : 150,
    textAlign: "center",
    // height: obj.height ? Math.min(150, obj.height) : 150,
    fontSize: Math.log(24 * scale) * 7,
    originX: "center",
    originY: "center",
    fontFamily: "Kalam,Inter V, Inter, sans-serif",
    selectable: false,
    evented: false,
    // Setting id for which this label is for
    // @ts-ignore
    labelFor: obj.id,

    fill: prefersDarkColorscheme() ? "white" : "black",
    shadow: dropShadow(),
  });

  // 	// adding reference to label object so we can keep them in sync
  //   // @ts-ignore
  //   obj.set("labelOverlay", label);

  return label;
};

const LabelOverlay = () => {
  const canvas = useSelector(
    (state: RootState) => getCanvas(state.canvas) as EcogardenCanvas | undefined
  );

  useEffect(() => {
    if (!canvas) {
      return;
    }

    // apply labels text to objects position

    canvas
      .getObjects()
      .filter(({ label }) => label !== undefined)
      .forEach((obj) => {
        const label = addLabel(obj);
        label.set("subtype", "label-overlay");
        canvas.add(label);
        canvas.requestRenderAll();
      });

    const handleObjectModified = (e: fabric.IEvent) => {
      // update label position?
      if (!e.target) {
        return;
      }

      // @ts-ignore
      if (e.target.labelOverlay) {
        // @ts-ignore
        const label = e.target.labelOverlay;
        const scale = findRelativeScale(e.target);
        label.set({
          top: e.target.top,
          left: e.target.left,
          fontSize: Math.log(24 * scale) * 7,
        });
        // TODO: update text scale based on size
        label.setCoords();
      } else {
        // @ts-ignore
        if (!e.target.label) {
          return;
        }

        const label = addLabel(e.target);
        label.set({
          top: e.target.top,
          left: e.target.left,
        });

        canvas.add(label);
        // canvas._objects.sort(sortCanvasObjects);
        canvas.requestRenderAll();
      }
    };

    const handleMoving = (e: fabric.IEvent) => {
      if (!e.target) {
        return;
      }

      // @ts-ignore
      if (e.target.labelOverlay) {
        // @ts-ignore
        const label = e.target.labelOverlay;
        label.set({
          top: e.target.top,
          left: e.target.left,
        });
      }
    };

    canvas.on("object:modified", handleObjectModified);
    canvas.on("object:moving", handleMoving);
    return function cleanup() {
      canvas.off("object:modified", handleObjectModified);
      canvas.off("object:moving", handleMoving);
      canvas
        .getObjects()
        .filter(
          ({ type, subtype }) => type === "text" && subtype === "label-overlay"
        )
        .forEach((obj) => {
          canvas.remove(obj);
          canvas.requestRenderAll();
        });
    };
  }, [canvas]);
  return <></>;
};

export default LabelOverlay;
